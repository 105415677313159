export default {
    blue: '#0066CC',
    lavendar: '#ADBDFF',
    purple: '#7700FF',
    yellow: '#F4BE0D',
    light: '#EDF5FD',
    dark: '#00171F',
    black: '#000000',
    white: '#FFFFFF',
    white50: 'rgba(255, 255, 255, 0.5)',
    white80: 'rgba(255, 255, 255, 0.8)'
}