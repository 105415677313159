import React from 'react'
import { createGlobalStyle } from 'styled-components'
import * as tachyons from 'tachyons'

const GlobalStyle = createGlobalStyle`
  ${tachyons}

  @import url('https://fonts.googleapis.com/css?family=Lato:400,700&display=swap');

  *, *::before, *::after {
    font-family: 'Lato', sans-serif;
  }

  .BrainhubCarousel {
    display: flex;
    align-items: center;
    .BrainhubCarousel__trackContainer {
      .BrainhubCarousel__track {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        &.BrainhubCarousel__track--transition {
          transition: transform;
        }
      }
    }
  }

  /* arrows */
  .BrainhubCarousel__arrows {
    cursor: pointer;
  }
`

export default ({ children }) => (
    <div className="overflow-x-hidden">
        <GlobalStyle />
        {children}
    </div>
)