import styled, { css } from 'styled-components'

export default styled.section`
    ${props => props.viewportHeight && css`height: 100vh;`}
    ${props => props.backgroundColor && css`background-color: ${props.backgroundColor};`}
    ${props => props.backgroundImage && css`
        background-image: url(${props.backgroundImage});
        background-repeat: no-repeat;
        background-size: cover;
    `}

    ${props => props.verticalSpace ? css`padding: 10rem 0 6rem;` : css`padding: 5rem 0;`}

    @media (min-width: 600px) {
        ${props => props.verticalSpace ? css`padding: 16rem 0 20rem;` : css`padding: 5rem 0;`}
    }
`